import React from "react";
import ServiceCard from "./ServiceCard";
import { ServiceData } from "../../parseJson";

function Services() {
  return (
    <div className="services main-section container-fluid" id="services">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-align-center">
            <div className="heading-container">
              <p className="heading-subtitle">{ServiceData.subtitle}</p>
              <h2 className="heading-title">{ServiceData.title}</h2>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2">
          {ServiceData.services.map((service) => (
            <div class="col mb-4">
              <ServiceCard services={service} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
