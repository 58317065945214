import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Nav from "./components/nav/Nav";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Portfolio from "./components/portfolio/Portfolio";
import Resume from "./components/resume/Resume";
import Contactus from "./components/contactus/Contactus";
import Interests from "./components/interests/Interests";
import Services from "./components/services/Services";

function App() {
  return (
    <div className="app">
      <Nav />
      <Home />
      <About />
      <Portfolio />
      <Resume />
      <Services/>
      <Interests />
      <Contactus />
    </div>
  );
}

export default App;
