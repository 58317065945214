import React, { useState } from "react";
import "./Nav.css";
import logoImage from "../../images/profile.jpg";
import { Link } from "react-scroll";
import { FaExternalLinkAlt } from "react-icons/fa";
import { HomeData } from "../../parseJson";

function Nav() {
  const [navbarColor, setNavbarColor] = useState(false);
  const [hashNavList, setHashNavList] = useState({
    about: "About",
    portfolio: "Portfolio",
    resume: "Resume",
    services: "Services",
    interests: "Interests",
    contactme: "Partner with Us",
  });

  const changeNavbar = () => {
    if (window.scrollY >= 500) {
      setNavbarColor(true);
    } else {
      setNavbarColor(false);
    }
  };

  window.addEventListener("scroll", changeNavbar);

  return (
    <nav
      className={
        "navbar navbar-expand-md fixed-top navbar-small " +
        (navbarColor ? "navbar-color-dark" : "navbar-color-transparent")
      }
    >
      <div className="nav-cont">
        <div className="logoContainer">
          <Link
            className="navbar-brand nav-logo"
            to="home"
            smooth={true}
            offset={-70}
            duration={500}
          >
            <img className="logo img-fluid" src={logoImage} alt="logo" />
          </Link>
        </div>

        <button
          className="navbar-toggler ml-auto collapsed"
          data-toggle="collapse"
          data-target="#navBarContent"
          aria-controls="navBarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          role="button"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div
        className="collapse navbar-collapse order-1 order-lg-0"
        id="navBarContent"
      >
        <ul className="myNavList navbar-nav ml-auto">
          {Object.entries(hashNavList).map(([hashId, hashName]) => {
            return (
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to={hashId}
                  smooth={true}
                  spy={true}
                  offset={-70}
                  duration={500}
                >
                  {hashName}
                </Link>
              </li>
            );
          })}

          <li>
            <a
              href={HomeData.navBarHireMe}
              target="_blank"
              rel="noreferrer"
              className="hire-button-a"
            >
              <button
                className="btn button-main button-scheme submit-button"
                id="submit-button-default"
              >
                <span>Hire Me</span>
                <FaExternalLinkAlt />
              </button>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
