import React from "react";
import "./Card.css";

function ServiceCard({ services }) {
  console.log("sesrvices", services);
  return (
    <div className="card h-100">
      <div className="card-body">
        <h5 className="card-title">{services.title}</h5>
        <p className={`card-text card-text-bold`}>{services.description}</p>
        <div className="services">
          <ul>
            {services.feature.map((feature) => (
              <li>
                <span className="service-title">{feature.title}</span>
                <span className="service-title">: </span>
                <span className="card-text card-text-bold">
                  {feature.description}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="benefits">
          <p className="service-title">{services.benefits.title}</p>
          <div className="services">
            <ul>
              {services.benefits.feature.map((feature) => (
                <li>
                  <span className="card-text card-text-bold">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="card-footer-container ">
        <div className="card-footer d-flex justify-content-around">
          <a href={services.action.link}>
            <button
              type="button"
              className="btn btn-outline-primary btn-custom"
            >
              {services.action.title}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
